import useParentsProps from "@/hooks/useParentsProps";
import { useCheckoutSelector } from "@/store/features/checkout";

export const useDefaultLang = (isStorybook = false) => {
    const {
        userSettings: { userSelectedLang: checkoutSelectedLang }
    } = useCheckoutSelector();
    const { parentUser } = useParentsProps();

    if (isStorybook) {
        return "en";
    }
    let defaultLang = "en";

    defaultLang = parentUser
        ? parentUser.languageId
        : checkoutSelectedLang || "en";

    return defaultLang;
};
