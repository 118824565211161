/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

declare function route(
    name: string,
    params?: string | number | { [key: string]: string }
): string;

export const startRegistration = (studentId: number) => {
    return axios.post(route("parents.start-registration", studentId));
};

export const autoSaveRegistration = (data: any) => {
    const { studentId, govRegId, jsonData } = data;

    return axios.post(route("parents.autosave-registration", { studentId, govRegId }), { data: jsonData });
};

export const finalSaveRegistration = (dataObject: any) => {
    const { studentId, govRegId, jsonData } = dataObject;

    return axios.post(route("parents.save-final-form", { studentId, govRegId }), { data: jsonData });
};

export const getRegistration = (data: any) => {
    const { studentId, govRegId } = data;

    return axios.get(route("parents.get-registration", { studentId, govRegId }));
};
