import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import registrationSliceReducer from "./slices/registrationSlice";
import { registrationPersistConfig } from "./registrationPersistConfig";

export { default as useRegistrationDispatch } from "./useRegistrationDispatch";
export {
    useRegistrationSelector,
    useStartRegistrationSelector,
    useAutoSaveRegistrationSelector,
    useFinalSaveRegistrationSelector,
    useGetRegistrationSelector
} from "./useRegistrationSelector";

const registrationReducerRoot = combineReducers({
    registration: registrationSliceReducer
});

export type RegistrationRootState = ReturnType<typeof registrationReducerRoot>;

const persistedRegistrationReducer = persistReducer<RegistrationRootState>(
    registrationPersistConfig,
    registrationReducerRoot
);

export default persistedRegistrationReducer;
