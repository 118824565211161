import { fetchProductFamilies } from "@/store/features/checkout/checkoutThunks";
import { ProductFamilyInterface } from "@/ts/interfaces/Checkout/ProductFamilyInterface";
import { ProductFamilyApiInterface } from "@/ts/interfaces/Checkout/api/ProductFamilyApiInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UserSettingsState {
    productFamilies: ProductFamilyInterface[] | null;
    productFamiliesResponse: ProductFamilyApiInterface[] | null;
    isProductFamiliesLoading: boolean;
    userSelectedCountry: string;
    userSelectedState: string;
    userSelectedLang: string;
    currency: "AUD" | "USD";
}

const initialState: UserSettingsState = {
    productFamilies: null,
    productFamiliesResponse: null,
    isProductFamiliesLoading: false,
    userSelectedCountry: "",
    userSelectedState: "",
    userSelectedLang: "en",
    currency: "USD"
};

const userSettingsSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        setProductFamilies(
            state,
            action: PayloadAction<ProductFamilyInterface[]>
        ) {
            state.productFamilies = action.payload;
        },
        setProductFamiliesResponse(
            state,
            action: PayloadAction<ProductFamilyApiInterface[]>
        ) {
            state.productFamiliesResponse = action.payload;
        },
        setUserSelectedCountry(state, action: PayloadAction<string>) {
            state.userSelectedCountry = action.payload;
        },
        setUserSelectedState(state, action: PayloadAction<string>) {
            state.userSelectedState = action.payload;
        },
        setUserSelectedLang(state, action: PayloadAction<string>) {
            state.userSelectedLang = action.payload;
        },
        setCurrency(state, action: PayloadAction<"AUD" | "USD">) {
            state.currency = action.payload;
        },
        resetCheckoutState() {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchProductFamilies.pending, state => {
                state.isProductFamiliesLoading = true;
                // state.fetchError = null;
            })
            .addCase(fetchProductFamilies.fulfilled, (state, action) => {
                state.isProductFamiliesLoading = false;
                state.productFamilies = action.payload.productFamilies;
                state.productFamiliesResponse =
                    action.payload.productFamiliesResponse;
            })
            .addCase(fetchProductFamilies.rejected, state => {
                state.isProductFamiliesLoading = false;
                state.productFamilies = [];
                state.productFamiliesResponse = [];
                // state.fetchError = action.payload as string;
            });
    }
});

export const {
    setProductFamilies,
    setUserSelectedCountry,
    setUserSelectedState,
    setUserSelectedLang,
    setCurrency,
    resetCheckoutState
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
