import { createTransform } from "redux-persist";
import { type RegistrationState, INITIAL_SUBSTATE } from "./slices/registrationSlice";

export const registrationTransform = createTransform<
    RegistrationState,
    Partial<RegistrationState>
>(
    // ---------------------------
    // 1) Outbound to localStorage
    // ---------------------------
    inboundState => {
        // We want to store getRegistration
        const { getRegistration } = inboundState;
        return { getRegistration };
    },

    // ---------------------------
    // 2) Inbound from localStorage
    // ---------------------------
    outboundState => {
        return {
            // We did NOT persist these, so restore to defaults:
            startRegistration: { ...INITIAL_SUBSTATE },
            finalSaveRegistration: { ...INITIAL_SUBSTATE },
            autoSaveRegistration: { ...INITIAL_SUBSTATE },
            // Keep or restore what was persisted
            getRegistration: outboundState.getRegistration ?? { ...INITIAL_SUBSTATE }
        };
    },
    {
        whitelist: ["registration"]
    }
);
