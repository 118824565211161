import { AddSubjectChangeApiInterface } from "@/ts/interfaces/Checkout/api/AddSubjectChangeApiInterface";
import { ProductFamilyApiInterface } from "@/ts/interfaces/Checkout/api/ProductFamilyApiInterface";
import axios from "axios";

import { AddPremiumServiceApiInterface } from "@ts/interfaces/Checkout/api/AddPremiumServiceApiInterface";
import { ChargebeeSubscriptionApiInterface } from "@ts/interfaces/Checkout/api/ChargeApiInterface.ts";
import { UserCheckoutStateApiInterface } from "@ts/interfaces/Checkout/api/UserCheckoutStateApiInterface";
import { ValidateCouponApiInterface } from "@ts/interfaces/Checkout/api/ValidateCouponApiInterface";

declare function route(
    name: string,
    params?: string | { [key: string]: string }
): string;

export const fetchProductFamiliesApi = (productFamilyUrl: string) => {
    if (productFamilyUrl === "") {
        return null;
    }

    return axios.get<ProductFamilyApiInterface[]>(productFamilyUrl);
};

export const fetchExistingCardsApi = (chargebeeId: string) => {
    return axios.get(
        route("api.chargebee.getPaymentSources", { id: chargebeeId }) // nosonar
    );
};

export const postCheckoutStateApi = (
    data: UserCheckoutStateApiInterface,
    token: string | null
) => {
    return axios.post(route("api.token.store", token ?? undefined), data); // nosonar
};

export const fetchCheckoutStateApi = (token: string) => {
    return axios.get(route("api.token.retrieve", token)); // nosonar
};

export const postCreatePaymentIntentApi = (
    data: ChargebeeSubscriptionApiInterface
) => {
    return axios.post(route("api.subscription.create-payment-intent"), data); // nosonar
};

export const postCreateCbSubscriptionApi = (
    data: ChargebeeSubscriptionApiInterface
) => {
    return axios.post(route("api.subscription.create-subscription"), data); // nosonar
};

export const postAddPremiumServiceApi = (
    data: AddPremiumServiceApiInterface
) => {
    return axios.post(route("api.premiumService.create"), data); // nosonar
};

export const postAddSubjectChangesApi = (
    data: AddSubjectChangeApiInterface
) => {
    return axios.post(route("api.chargebee.addSubjectChanges"), data); // nosonar
};

export const postValidateCouponApi = (data: ValidateCouponApiInterface) => {
    return axios.post(route("api.chargebee.applyCoupon"), data); // nosonar
};

export const checkExistingUserApi = (data: { email: string }) => {
    return axios.get(route("auth.check-existing-user", data)); // nosonar
};

export const recaptchaVerifyApi = (data: { token: string }) => {
    return axios.post(route("api.recaptcha.verify"), data); // nosonar
};

export const validateEmailApi = (email: string, apiKey: string) => {
    return axios.get(
        `https://emailvalidation.abstractapi.com/v1/?api_key=${apiKey}&email=${email}`
    );
};

export const validatePhoneApi = (phone: string, apiKey: string) => {
    const response = axios.get(
        `https://phonevalidation.abstractapi.com/v1/?api_key=${apiKey}&phone=${phone.replace(
            /\+/g,
            ""
        )}`
    );

    return response;
};
