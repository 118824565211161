/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  startRegistrationThunk,
  autoSaveRegistrationThunk,
  finalSaveRegistrationThunk,
  getRegistrationThunk
} from "../registrationThunks";

interface RegistrationSubState {
  isLoading: boolean;
  isSuccess: boolean;
  error: string | null;
  data: any;
}

export const INITIAL_SUBSTATE: RegistrationSubState = {
    isLoading: false,
    isSuccess: false,
    error: null,
    data: null
};

export interface RegistrationState {
  startRegistration: RegistrationSubState;
  autoSaveRegistration: RegistrationSubState;
  finalSaveRegistration: RegistrationSubState;
  getRegistration: RegistrationSubState;
}

const initialState: RegistrationState = {
  startRegistration: { ...INITIAL_SUBSTATE },
  autoSaveRegistration: { ...INITIAL_SUBSTATE },
  finalSaveRegistration: { ...INITIAL_SUBSTATE },
  getRegistration: { ...INITIAL_SUBSTATE }
};

type RegistrationSubStateKey =
  | "startRegistration"
  | "autoSaveRegistration"
  | "finalSaveRegistration"
  | "getRegistration";

const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    resetRegistrationState: () => initialState,

    // Clear some or all sub-states
    clearRegistrationData: (
      state,
      action: PayloadAction<{ section?: RegistrationSubStateKey }>
    ) => {
      const { section } = action.payload;
      if (!section) {
        // Clear all
        state.startRegistration = { ...INITIAL_SUBSTATE };
        state.autoSaveRegistration = { ...INITIAL_SUBSTATE };
        state.finalSaveRegistration = { ...INITIAL_SUBSTATE };
        state.getRegistration = { ...INITIAL_SUBSTATE };
      } else {
        // Clear only the specified
        state[section] = { ...INITIAL_SUBSTATE };
      }
    }
  },
  extraReducers: builder => {
    // ---- startRegistrationThunk ----
    builder
      .addCase(startRegistrationThunk.pending, state => {
        state.startRegistration.isLoading = true;
        state.startRegistration.error = null;
      })
      .addCase(startRegistrationThunk.fulfilled, (state, action) => {
        state.startRegistration.isLoading = false;
        state.startRegistration.isSuccess = true;
        state.startRegistration.data = action.payload.data;
      })
      .addCase(startRegistrationThunk.rejected, (state, action) => {
        state.startRegistration.isLoading = false;
        state.startRegistration.error = action.payload
          ? String(action.payload)
          : "Unknown error";
      })

      // ---- autoSaveRegistrationThunk ----
      .addCase(autoSaveRegistrationThunk.pending, state => {
        state.autoSaveRegistration.isLoading = true;
        state.autoSaveRegistration.error = null;
      })
      .addCase(autoSaveRegistrationThunk.fulfilled, (state, action) => {
        state.autoSaveRegistration.isLoading = false;
        state.autoSaveRegistration.isSuccess = true;
        state.autoSaveRegistration.data = action.payload.data;
      })
      .addCase(autoSaveRegistrationThunk.rejected, (state, action) => {
        state.autoSaveRegistration.isLoading = false;
        state.autoSaveRegistration.error = action.payload
          ? String(action.payload)
          : "Unknown error";
      })

      // ---- finalSaveRegistrationThunk ----
      .addCase(finalSaveRegistrationThunk.pending, state => {
        state.finalSaveRegistration.isLoading = true;
        state.finalSaveRegistration.error = null;
      })
      .addCase(finalSaveRegistrationThunk.fulfilled, (state, action) => {
        state.finalSaveRegistration.isLoading = false;
        state.finalSaveRegistration.isSuccess = true;
        state.finalSaveRegistration.data = {
          ...state.finalSaveRegistration.data,
          ...action.payload
        };
      })
      .addCase(finalSaveRegistrationThunk.rejected, (state, action) => {
        state.finalSaveRegistration.isLoading = false;
        state.finalSaveRegistration.error = action.payload
          ? String(action.payload)
          : "Unknown error";
      })

      // ---- getRegistrationThunk ----
      .addCase(getRegistrationThunk.pending, state => {
        state.getRegistration.isLoading = true;
        state.getRegistration.error = null;
      })
      .addCase(getRegistrationThunk.fulfilled, (state, action) => {
        state.getRegistration.isLoading = false;
        state.getRegistration.isSuccess = true;
        state.getRegistration.data = action.payload.data;
      })
      .addCase(getRegistrationThunk.rejected, (state, action) => {
        state.getRegistration.isLoading = false;
        state.getRegistration.error = action.payload
          ? String(action.payload)
          : "Unknown error";
      });
  }
});

export const {
  resetRegistrationState,
  clearRegistrationData
} = registrationSlice.actions;

export default registrationSlice.reducer;
