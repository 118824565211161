import programReducer from "@/store/features/checkout/slices/programSlice";
import userSettingsReducer from "@/store/features/checkout/slices/userSettingsSlice";
import useCheckoutDispatch from "@/store/features/checkout/useCheckoutDispatch";
import useCheckoutSelector from "@/store/features/checkout/useCheckoutSelector";
import { combineReducers } from "@reduxjs/toolkit";

const checkoutReducer = combineReducers({
    userSettings: userSettingsReducer,
    program: programReducer
});

export default checkoutReducer;

export { useCheckoutDispatch, useCheckoutSelector };
