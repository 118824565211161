import { fetchProductFamiliesApi } from "@/api/checkoutApi";
import { ProductFamilyInterface } from "@/ts/interfaces/Checkout/ProductFamilyInterface";
import { ProductFamilyApiInterface } from "@/ts/interfaces/Checkout/api/ProductFamilyApiInterface";
import { mapProductFamiliesApiData } from "@/utils/checkoutDataMapper";
import { sortItems } from "@/utils/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

export const fetchProductFamilies = createAsyncThunk<
    {
        productFamilies: ProductFamilyInterface[];
        productFamiliesResponse: ProductFamilyApiInterface[];
    },
    {
        productFamilyUrlsByCountry: Record<string, string>;
        country: string;
        lang: string;
    },
    { rejectValue: string }
>(
    "fetchProductFamilies",
    async (
        { productFamilyUrlsByCountry, country, lang },
        { rejectWithValue }
    ) => {
        const updatedCountry = country === "international" ? "AU" : country;
        const productFamilyUrl =
            Object.entries(productFamilyUrlsByCountry).find(
                ([countryCode]) => countryCode === updatedCountry
            )?.[1] ?? "";

        const productFamilyUrlBasedOnLang = productFamilyUrl.replace(
            /\.json$/,
            lang !== "en" ? `_${lang}.json` : ".json"
        );

        try {
            const response = await fetchProductFamiliesApi(
                productFamilyUrlBasedOnLang
            );
            if (response?.status !== 200) {
                throw new Error("Failed to fetch Product families");
            }

            const convertedProductFamilies = mapProductFamiliesApiData(
                sortItems<ProductFamilyApiInterface>(
                    response?.data ?? [],
                    "order",
                    ["addons", "plans"]
                )
            );

            const payload = {
                productFamilies: convertedProductFamilies,
                productFamiliesResponse: response?.data ?? []
            };

            return payload;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }
            console.error("Unexpected error:", error);
            return rejectWithValue(
                "An unexpected error occurred while fetching product families."
            );
        }
    }
);
