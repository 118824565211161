/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    startRegistration,
    autoSaveRegistration,
    finalSaveRegistration,
    getRegistration,
} from "@/api/registrationApi";

export const startRegistrationThunk = createAsyncThunk(
    "registration/start",
    async (studentId: number, { rejectWithValue }) => {
        try {
            const response = await startRegistration(studentId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const autoSaveRegistrationThunk = createAsyncThunk(
    "registration/autoSave",
    async (data: { studentId: number; govRegId: number; jsonData: any }, { rejectWithValue }) => {
        try {
            const response = await autoSaveRegistration(data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const finalSaveRegistrationThunk = createAsyncThunk(
    "registration/finalSave",
    async (dataObject: { studentId: number; govRegId: number; jsonData: any }, { rejectWithValue }) => {
        try {
            const response = await finalSaveRegistration(dataObject);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getRegistrationThunk = createAsyncThunk(
    "registration/get",
    async (data: { studentId: number; govRegId: number }, { rejectWithValue }) => {
        try {
            const response = await getRegistration(data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
