import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ProgramSlice {
    theme: "light" | "dark";
    language: string;
}

const initialState: ProgramSlice = {
    theme: "light",
    language: "en"
};

const programSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setTheme(state, action: PayloadAction<"light" | "dark">) {
            state.theme = action.payload;
        },
        setLanguage(state, action: PayloadAction<string>) {
            state.language = action.payload;
        }
    }
});

export const { setTheme, setLanguage } = programSlice.actions;

export default programSlice.reducer;
