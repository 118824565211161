/* eslint-disable @typescript-eslint/no-explicit-any */

import type { PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { registrationTransform } from "./registrationTransform";

export const registrationPersistConfig: PersistConfig<any> = {
  key: "registration",
  storage,
  whitelist: ["registration"],
  transforms: [registrationTransform]
};
