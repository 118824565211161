import { useSelector } from "react-redux";

import { RootState } from "@/store";

function useCheckoutSelector() {
    const checkout = useSelector((state: RootState) => state.checkout);
    return checkout;
}

export default useCheckoutSelector;
